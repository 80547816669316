<template>
  <div class="img-decypt">
    <van-image
      :src="decyImg"
      class="vanImage swiper-zoom-container"
      fit="cover"
      :round="round"
      v-lazy="decyImg"
      @load="onLoad"
      @error="onError"
      @click="toLink(itemData)"
    >
      <template v-slot:loading>
        <img :src="defaultImg" class="loading" v-if="!isSplash" />
        <div class="splash-loading-img" v-else></div>
      </template>
      <template v-slot:error>
        <img :src="defaultImg" class="loading" v-if="!isSplash" />
        <div class="splash-error-img" v-else></div>
      </template>
      <slot></slot>
    </van-image>
  </div>
</template>
<script>
import { Image as VanImage } from 'vant';
import { mapGetters } from 'vuex';
import { imgDecyptApi } from '@/api/app';
import { jumpAdv } from '@/utils/getConfig';

export default {
  props: {
    itemData: {
      type: Object,
    },
    src: {
      type: String,
      required: true,
      default() {
        return '';
      },
    },
    isNeedDecypt: {
      //是否需要解密
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
    isSplash: {
      //是否来自启动页
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
    round: {
      //图片是否显示为圆形
      type: Boolean,
      required: false,
      default() {
        return false;
      },
    },
  },
  components: {
    [VanImage.name]: VanImage,
  },
  data() {
    return {
      decyImg: '',
      defaultImg: require('@/assets/png/place.png'),
    };
  },
  computed: {
    ...mapGetters({
      imgApi: 'imgApi',
    }),
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (!this.isNeedDecypt) {
        if (this.itemData != null) {
          this.decyImg = this.itemData['cover'];
        } else {
          this.decyImg = this.src;
        }
      } else {
        let src = null;
        if (this.itemData != null) {
          src = this.itemData['cover'];
        } else {
          src = this.src;
        }
        try {
          let imgApi = this.imgApi;
          if (imgApi.slice(-1) != '/') {
            imgApi = imgApi + '/';
          }
          if (!imgApi || !src) return;
          imgDecyptApi(imgApi + src)
            .then((url) => {
              this.decyImg = url;
            })
            .catch((err) => {
              this.$emit('error');
            });
        } catch (error) {
          this.$emit('error');
          console.log(error);
        }
      }
    },
    onLoad() {
      if (this.isSplash && this.decyImg) {
        this.$emit('imgLoad');
      }
    },
    onError() {
      if (this.isSplash && this.decyImg) {
        this.$emit('error');
      }
    },
    toLink(item) {
      if (item != null) {
        jumpAdv(item);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-image__loading {
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.splash-loading-img,
.splash-error-img {
  width: 100%;
  height: 100%;
  background: url('../../assets/png/ad.webp') center no-repeat;
  background-size: 100% 100%;
  position: relative;
}

.img-decypt {
  width: 100%;
  height: 100%;

  .vanImage {
    width: 100%;
    height: 100%;

    .loading {
      width: 100%;
      height: 100%;
    }

    /deep/ .van-image__error {
      overflow: hidden;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
}
</style>
